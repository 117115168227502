
import Vue from "vue";
import api from "@/services/dataService";
import notification from "@/services/notificationService";
import store from "@/store";
import { User, userRoles, Vehicle } from "@/shared/models";

export default Vue.extend({
  name: "AllUserRightSidebar",
  props: {
    userId: String,
    user: Object
  },
  data() {
    return {
      userRoles,
      editUserToggle: false,
      archiveUserToggle: false,
      editUserDetail: {
        salutation: undefined,
        firstName: undefined,
        lastName: undefined,
        roles: undefined as undefined | string[] | string,
        vehicles: undefined as undefined | string[]
      }
    };
  },
  computed: {
    selectedTenant() {
      return store.state.selectedTenant;
    },
    vehicles() {
      return store.state.vehicles;
    }
  },
  watch: {
    userId: function () {
      if (!this.userId) {
        this.$store.dispatch("setUserDetail", undefined);
      }
    }
  },
  methods: {
    async updateUserDetail() {
      const request = {
        salutation: this.editUserDetail.salutation,
        firstName: this.editUserDetail.firstName,
        lastName: this.editUserDetail.lastName,
        roles: [this.editUserDetail.roles],
        vehicles: this.editUserDetail.vehicles?.map((id) => {
          return { id: id };
        })
      };
      await this.$store
        .dispatch("updateUserDetail", {
          userId: this.$route.query.id,
          user: request
        })
        .then(() => {
          this.editUserToggle = false;
          this.$emit("user-update");
        });
    },
    async archiveUser(flag: boolean) {
      await this.$store
        .dispatch("updateUserDetail", {
          userId: this.$route.query.id,
          user: { archived: flag }
        })
        .then(() => {
          this.archiveUserToggle = false;
          this.$emit("user-update");
          this.$emit("sidebar-closed");
        });
    },
    getVehicles() {
      this.$store.dispatch("getVehicles", {
        selectedTenant: this.selectedTenant
      });
    }
  }
});
