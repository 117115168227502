
import Vue from "vue";
import api from "@/services/dataService";
import notification from "@/services/notificationService";
import router from "@/router";
import { User, userRoles } from "@/shared/models";
import UsersRightSidebar from "@/components/UsersRightSidebar.vue";
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";
import { find } from "lodash";
export default Vue.extend({
  name: "Users",
  components: { UsersRightSidebar, NoTenantSelectedMessage },
  data() {
    return {
      userRoles,
      activeUsersView: true,
      tableProps: {
        data: [] as User[],
        loading: false,
        total: 0,
        page: 1,
        perPage: (window.innerHeight / 88).toFixed(0) // Calculating rows per page according to screen height
      },
      selected: undefined as undefined | User,
      filteredUsers: undefined as undefined | User[],
      addUserToggle: false,
      filters: {
        searchQuery: undefined as undefined | string
      },
      addUserDetail: {
        salutation: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        roles: "" as string | string[],
        vehicles: undefined as undefined | string[]
      }
    };
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    vehicles() {
      return this.$store.state.vehicles;
    },
    selectedTenant() {
      return this.$store.state.selectedTenant;
    }
  },
  watch: {
    selectedTenant: function () {
      this.initializeView();
      this.removeSelection();
    },
    users: function () {
      if (this.$route.query.id && this.users) {
        const selectedUser = find(this.users.items, {
          id: this.$route.query.id
        } as User);
        this.selected = selectedUser as User;
      }
      this.filterUsers();
    }
  },
  created() {
    this.initializeView();
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        this.getUsers();
        this.getVehicles();
      }
    },
    //Handle page-change event
    onPageChange(page: number) {
      this.tableProps.page = page;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page.toString()
        }
      });
      this.getUsers();
    },
    openAddUserModal() {
      this.addUserToggle = true;
      this.removeSelection();
    },
    filterUsers() {
      // load users to filter
      this.filteredUsers = this.users;
      // check if search query
      if (this.filters.searchQuery) {
        this.filteredUsers = this.filteredUsers?.filter((user) => {
          return (
            user.firstName
              ?.toLowerCase()
              .includes(this.filters.searchQuery!.toLowerCase()) ||
            user.lastName
              ?.toLowerCase()
              .includes(this.filters.searchQuery!.toLowerCase())
          );
        });
      }
    },
    showDetail(payload: User) {
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: payload.id
        }
      });
    },
    removeSelection() {
      this.selected = undefined;
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: undefined
        }
      });
    },
    addUser() {
      const request = {
        ...this.addUserDetail,
        isAdmin: true,
        roles: [this.addUserDetail.roles]
      };
      this.$data.addUserDetail.roles === this.userRoles.Admin
        ? delete request.vehicles
        : (request.vehicles = this.$data.addUserDetail.vehicles.map(
            (vehicleId: string) => {
              return {
                id: vehicleId
              };
            }
          ));

      api
        .post("/users/register", request)
        .then((res) => {
          notification.success("User added");
          this.addUserToggle = false;
          this.addUserDetail = {
            salutation: undefined,
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            roles: "",
            vehicles: undefined
          };
          this.getUsers();
        })
        .catch((err) => {
          notification.error(err.data.message);
        });
    },
    async getUsers() {
      this.tableProps.loading = true;

      const request = {
        page: this.tableProps.page,
        archived: !this.activeUsersView,
        limit: this.tableProps.perPage,
        selectedTenant: this.selectedTenant
      };
      await this.$store.dispatch("getUsers", request);
      if (this.users) {
        this.tableProps.data = this.users;
        this.tableProps.loading = false;
      }
    },
    getVehicles() {
      this.$store.dispatch("getVehicles", {
        selectedTenant: this.selectedTenant
      });
    }
  }
});
